<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card v-if="true">
            <v-card-title>
                <v-spacer></v-spacer>
                <create-button @click="dialog.state=true" ></create-button>
                <v-divider vertical class="mx-2"></v-divider>
                <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>
            <v-data-table height="600px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"

            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.confirmedDate`]="{ item }">
                    {{ item.confirmedDate == "01/01/0001" ? "" : item.confirmedDate}}
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <!-- ********* Edit ****** -->
                    <!-- <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon> -->

                    <!-- ********* Xoa ****** -->
                    <v-btn x-small color="error" @click="deleteItem(item)" v-if="!item.isLock">
                        <v-icon small>
                            mdi-delete
                        </v-icon> Thu hồi
                    </v-btn>
                    
                    <v-icon
                        small color="disable"
                        v-else
                    >
                        mdi-lock
                    </v-icon>
                </template>

                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field dense v-model="filters.code" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.status" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                                <v-text-field dense v-model="filters.leaveAmountType" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.leavePlan" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2 dense outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                            <!-- <v-text-field dense v-model="filters.confirmBy" placeholder="Chọn"></v-text-field> -->
                        </td>
                        <td>
                            <!-- <date-picker2> -->
                                <!-- <v-text-field dense v-model="filters.confirmDate" placeholder="Chọn"></v-text-field> -->
                            <!-- </date-picker2> -->
                        </td>
                        <td>
                            <!-- <v-text-field dense v-model="filters.note" placeholder="Chọn"></v-text-field> -->
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        "create-dialog": Create,
        "data-filter": DataFilter
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
                this.fetchCity();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month: {
            handler(val)
            {
                this.filters.from = moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to = moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },

    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data() {
        return {
            ROOT_URL: "LeaveRequests/",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //filters
            month: moment().format("YYYY-MM"),
            filters:{
                code:null,
                employeeName:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            rowCount: 0,

            //TABLE
            headers: [
                {text:"", value: "actions"},
                {text: "Mã", value: "code"},
                {text: "Trạng thái", value: "status"},
                {text: "Loại nghỉ", value: "leaveAmountType"},
                {text: "Loại phép", value: "leavePlan"},
                {text: "Ngày nghỉ", value: "from"},
                {text: "Tên nhân viên", value: "employeeName"},
                {text: "Phòng ban", value: "departmentName"},
                {text: "Người duyệt", value: "confirmBy"},
                {text: "Ngày duyệt", value: "confirmDate"},
                {text: "Ghi chú", value: "note"},
            ],

            items: [],
            options: {},
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },

    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },

        //DATA JS
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
                this.isLoading = false;
            })
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "self/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    
    created() {
        this.fetchData();
    }
}
</script>
<style scoped>
::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>